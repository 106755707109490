import { graphql } from "gatsby"
import React from "react"
import { withTrans } from "../withTrans"
import HighlightsComponent from "../components/HighlightsComponent"
import Layout from "../components/layout"
import HeroComponent from "../components/HeroComponent"
import { Tab } from "@headlessui/react"
import CardsList from "../components/CardsList"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    gcms {
      labPages(locales: $locale) {
        title
        description
        sliderImages(locales: en) {
          url
          mimeType
        }
      }
      labFacilities(locales: $locale) {
        title
        description
        id
        cardImage(locales: en) {
          url
        }
        slug
      }
      labTutorials(locales: $locale) {
        title
        description
        cardImage(locales: en) {
          url
        }
        slug
      }
      servicesCards(locales: $locale, where: { spaceService: Lab }) {
        title
        description
        id
        cardImage(locales: en) {
          url
        }
        slug
        spaceService
        typeOfService
      }
    }
  }
`
class Lab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      labPageData: this.props.data.gcms.labPages[0],
      labFacilitiesData: this.props.data.gcms.labFacilities,
      labTutorialsData: this.props.data.gcms.labTutorials,
      labServicesData: this.props.data.gcms.servicesCards,
    }
    this.state.labServicesData.forEach(function(element, index, array) {
      array[index].slug = "../services/"+element.slug;
    });
  }
  render() {
    const { t } = this.props
    return (
      <Layout>
        <Tab.Group
          defaultIndex={0}
        >
          <Tab.List>
            <div className="font-futurastd_book text-center lg:grid lg:grid-rows-1 lg:grid-cols-3 lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
              <Tab className="col-span-4 lg:col-span-1 row-span-1 uppercase w-full lg:w-auto">
                <div className="text-center inline-block bg-white">
                  <span className="text-2xl">&lt;</span>
                  <span className="tracking-title-3 text-base ml-3 mr-2">
                    {t("navbar_title_lab")}
                  </span>
                  <span className="text-2xl">&gt;</span>
                </div>
              </Tab>

              <Tab
                className={({ selected }) =>
                  selected
                    ? " font-futurastd_bold lg:order-first"
                    : "lg:order-first"
                }
              >
                <div className="bg-white lg:bg-title-double-slash lg:bg-left lg:bg-no-repeat lg:pl-8 text-label-fs-10 uppercase tracking-title-2 lg:inline-block leading-7 text-center">
                  <div className="">{t("label_tutorials")}</div>
                </div>
              </Tab>
              <div className="lg:hidden separator inline-block align-middle mx-1"></div>
              <Tab
                className={({ selected }) =>
                  selected ? " font-futurastd_bold" : ""
                }
              >
                <div className="bg-white lg:bg-title-double-slash lg:bg-left lg:bg-no-repeat lg:pl-8 text-label-fs-10 uppercase tracking-title-2 lg:inline-block leading-7 text-center">
                  <div>{t("label_services")}</div>
                </div>
              </Tab>
            </div>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <HeroComponent
                sliderItems={this.state.labPageData.sliderImages}
                pageData={this.state.labPageData}
              />
              <HighlightsComponent
                title={t("title_lab_facilities")}
                highlights={this.state.labFacilitiesData}
              />
            </Tab.Panel>
            <Tab.Panel>
              <HighlightsComponent highlights={this.state.labTutorialsData} />
            </Tab.Panel>
            <Tab.Panel>
              <CardsList items={this.state.labServicesData} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Layout>
    )
  }
}

export default withTrans(Lab)
